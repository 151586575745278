<div class="well">
    <!-- <div class="form-group">
        <label>
            {{ 'category_label' | translate }}
        </label>
        <input class="form-control" type="text" [formControl]="categoryControl" />
    </div>
    <div class="form-group">
        <label>
            {{ 'action_label' | translate }}
        </label>
        <input class="form-control" type="text" [formControl]="actionControl" />
    </div>
    <div class="form-group">
        <label>
            {{ 'label_label' | translate }}
        </label>
        <input class="form-control" type="text" [formControl]="labelControl" />
    </div> -->

    <span class="help-block small">
        {{ 'control_value_currency_hint' | translate }}
    </span>
    <div class="form-group" [class.has-error]="currencyControl.invalid">
        <label>
            {{ 'currency_label' | translate }}
        </label>
        <input class="form-control" placeholder="USD" type="text" [formControl]="currencyControl" (blur)="setValueControlValidator()"/>
        <span *ngIf="currencyControl.invalid" class="help-block small mb-0">
            {{ 'control_errors_required' | translate }}
        </span>
    </div>
    <div class="form-group" [class.has-error]="valueControl.invalid">
        <label>
            {{ 'value_label' | translate }}
        </label>
        <input class="form-control" placeholder="9.99" type="number" [formControl]="valueControl" (blur)="setCurrencyControlValidator()"/>
        <span *ngIf="valueControl.invalid" class="help-block small mb-0">
            {{ 'control_errors_required' | translate }}
        </span>
    </div>
    <div class="form-group">
        <label>
            {{ 'lead_source_label' | translate }}
        </label>
        <input class="form-control" type="text" [formControl]="leadSourceControl" />
    </div>
</div>
